import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../layout/seo"

import "./blog.scss"

export default function BlogPost({ data }) {
  const { markdownRemark: post } = data

  return (
    <>
      <Layout home={false}>
        <SEO title="Blog" />
        <div className="blog-content">
          <h1>{post.frontmatter.title}</h1>
          <p>by {post.frontmatter.author}</p>
          <p>{post.frontmatter.date}</p>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </Layout>
    </>
  )
}

export const blogPostQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        author
      }
    }
  }
`
